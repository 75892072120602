import React from 'react';
import './Footer.css'

function Footer() {
  return (
    <div>
      <div id='footer'>
        <div id='all-rights-reserved'>2023 RateMyCourse.co.nz. All Rights Reserved. Partnered with <a href='https://tutor4u.co.nz'>Tutor4U.co.nz</a></div>
        <div>
        
        </div>
      </div>
    </div>
  );
}

export default Footer;